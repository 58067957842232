import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import ReactPlayer from "react-player";
import {
  setPlayerVisible,
  setPinCodeModal,
  setLeftSliderPin,
} from "../../../redux/actions";
import { streaming, config } from "./helper";
import AboutEpg from "./AboutEpg";
import Controls from "./Controls";
import Volume from "./Volume";
import Progress from "./Progress";
import Loader from "./Loader";
import LeftSlider from "../LeftSlider/LeftSlider";
import BottomSlider from "../BottomSlider/BottomSlider";
import "../VideoPlayer.css";

// import { AiOutlineBlock } from "react-icons/ai";

let timeout;

const Player = ({
  data,
  epgs,
  setPlayerVisible,
  pincode,
  setPinCodeModal,
  setLeftSliderPin,
}) => {
  const [isPlaying, setIsPlaying] = useState(true);
  const [volume, setVolume] = useState(1);
  const [loader, setLoader] = useState(true);
  const [fullScreen, setFullScreen] = useState(false);
  const [fadeout, setFadeout] = useState(false);
  const { channel, epg } = data;

  const player = useRef(null);
  const [secPlayer, setSecPlayer] = useState(null);
  const [secUrl, setSecUrl] = useState(null);

  const [progress, setProgress] = useState(0);
  const [seeking, setSeeking] = useState(false);

  const [nextEpisode,setNextEpisode] = useState(null);

  useEffect(() => {
    let body = document.querySelector("body");
    body.style.overflow = "hidden";
    return () => {
      body.removeAttribute("style");
      setPinCodeModal(null);
    };
  }, [setPinCodeModal]);

  useEffect(() => {
    if (pincode === "success")
      setTimeout(() => {
        setLeftSliderPin(null);
      }, 2000);
  }, [pincode, setLeftSliderPin]);

  const toggleFullScreen = () => {
    if (fullScreen) {
      document.exitFullscreen();
      setFullScreen(false);
    } else {
      document.querySelector(".video_player_wrapper").requestFullscreen();
      setFullScreen(true);
    }
  };

  const classes = () =>
    fadeout ? "video_player_wrapper fadeout" : "video_player_wrapper";

  const onReady = () => {
    setIsPlaying(true);
    setLoader(false);
  };

  const onMouseMove = (e) => {
    let div = document
      .querySelector(".video_player_mouse_move")
      .getBoundingClientRect();
    if (div.left < e.pageX && e.pageY < div.bottom) {
      setFadeout(false);
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        setFadeout(true);
      }, 3000);
    } else {
      setFadeout(false);
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        setFadeout(false);
      }, 3000);
    }
  };

  const progressHandler = (state) => {
    if (!seeking) {
      setProgress(state.played);
    }
  };

  const disableSeeking = () => {
    setSeeking(false);
  };

  const enableSeeking = () => {
    setSeeking(true);
  };

  const enablePip = () => {
    setSecUrl(player.current.props.url);
    if (!secPlayer) {
      setSecPlayer(player.current.props);
    }
  };

  const disablePip = () => {
    setSecPlayer(null);
    setSecUrl(null);
  };

  const playNext = () => {
    epgs.forEach((check,index) => {
      if(check.id === epg.id){
        if(epgs[index+1].src){
        return setNextEpisode(epgs[index+1])
        }
      }
    })
    return null
    // return player.current.seekTo(player.current.getDuration() )
  }

  const playCurrent = () => {
    // console.log(channel)  
    return setNextEpisode(channel.current_epg)
  }

  const rewindHandler = () => {
    player.current.seekTo(0)
  }

  return (
    <div
      className={classes()}
      onClick={() => setIsPlaying(!isPlaying)}
      onDoubleClick={toggleFullScreen}
      onMouseMove={onMouseMove}
    >
      <ReactPlayer
        config={config(channel)}
        url={streaming(channel, epgs, epg)}
        onProgress={progressHandler}
        onReady={onReady}
        volume={parseInt(volume)}
        ref={player}
        playing={isPlaying}
        onEnded={playNext }
      />

      <ReactPlayer
        style={{ display: "none" }}
        config={config(channel)}
        url={secUrl ? secUrl : streaming(channel, epgs, epg)}
        onReady={onReady}
        volume={0}
        playing={true}
        pip={secPlayer ? true : false}
        onDisablePIP={disablePip}
      />

      <div className="close-player" onClick={() => setPlayerVisible(null)} />
      <div className="video_player_mouse_move" />
      <div
        className="video_player_bottom_side"
        onClick={(e) => e.stopPropagation()}
        onDoubleClick={(e) => e.stopPropagation()}
      >
        <div className="top_part">
          <AboutEpg channel={channel} />
          <Controls
            player={player}
            channel={channel}
            epg={epg}
            isPlaying={isPlaying}
            setIsPlaying={setIsPlaying}
            forward={playCurrent}
            rewind={rewindHandler}
          />
          {
            <Volume
              volume={volume}
              setVolume={setVolume}
              toggleFullScreen={toggleFullScreen}
              epg={epg}
              epgs={epgs}
              channel={channel}
              player={player}
              enablePip={enablePip}
            />
          }
        </div>
        {player.current && epg && (
          <Progress
            player={player}
            played={progress}
            disableSeeking={disableSeeking}
            enableSeeking={enableSeeking}
          />
        )}
        {epgs && epgs.length && <BottomSlider data={data} nextEpisode={nextEpisode} />}
      </div>
      {<LeftSlider fullScreen={fullScreen} data={data} />}
      {loader && <Loader />}
    </div>
  );
};

const mapStateToProps = (state) => ({
  data: state.playerVisible,
  pincode: state.leftSliderPin,
  epgs: state.epgs,
});

export default connect(mapStateToProps, {
  setPlayerVisible,
  setPinCodeModal,
  setLeftSliderPin,
})(Player);
