import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { FaHome, FaStar, FaCog, FaSistrix, FaTimes } from "react-icons/fa";
import { Services } from "../../services/Services";
import { setSearchValue } from "../../redux/actions";
import Logo from "../../images/root_logo.png";
import NewsIcon from "../../images/news.png";
import tv_menu_icon from "../../images/tv_menu_icon.png";
import internet_tv_menu_icon from "../../images/internet_tv_menu_icon.png";
import "./Navbar.css";

const Navbar = ({ lang, history, setSearchValue }) => {
  const [activeSearch, setActiveSearch] = useState(false);
  const [result, setResult] = useState([]);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      let navbar = document.querySelector(".navbar_wrapper");
      if (window.pageYOffset > 150) navbar.classList.add("sticky");
      else navbar.classList.remove("sticky");
    });
  });

  const onChange = (e) => {
    let searchValue = e.target.value;

    Services.searchEpgs({ term: searchValue, limit: 300 }).then((res) => {
      // console.log(res.data);
      setResult(res.data);
      let format = [];
      res.data.map((item) => format.push({ channel: item.channel, epg: item }));

      if (searchValue.length > 2) {
        setSearchValue(format);
        history.push("/search");
      }
    });
  };

  const closeSearch = () => {
    setActiveSearch(false);
    setSearchValue("");
  };

  const isActive = (path) =>
    window.location.pathname === path
      ? "navbar_item_active navbar_item"
      : "navbar_item";

  const activeClass = (path) =>
    window.location.pathname.includes(path)
      ? "navbar_item_active navbar_item"
      : "navbar_item";

  return (
    <div className="navbar_wrapper">
      <div className="navbar">
        <div className="container">
          <div className="navbar_holder">
            <Link to="/" className="nav_logo_holder">
              <img src={Logo} alt="logo" className="nav_logo" />
            </Link>
            <div className="navbar_items">
              <Link to="/" className={isActive("/")}>
                <span>
                  <FaHome />
                </span>
                {lang.menu.home}
              </Link>
              <Link to="/channels/epgs" className={activeClass("/channels")}>
                <span>
                  <img src={tv_menu_icon} alt="" />
                </span>
                {lang.menu.tv_channels}
              </Link>
              <Link
                to="/internetTV/m3u8"
                className={activeClass("/internetTV")}
              >
                <span>
                  <img src={internet_tv_menu_icon} alt="" />
                </span>
                {lang.menu.internet_tv}
              </Link>
              <Link to="/news" className={isActive("/news")}>
                <span>
                  <img src={NewsIcon} alt="" className="news_icon" />
                </span>
                {lang.menu.news}
              </Link>
              <Link
                to="/favorites/channel"
                className={activeClass("/favorites")}
              >
                <span>
                  <FaStar />
                </span>
                {lang.menu.favorites}
              </Link>
              <Link to="/settings/summary" className={activeClass("/settings")}>
                <span>
                  <FaCog />
                </span>
                {lang.menu.settings}
              </Link>
              <div className="search_holder hidden">
                <FaSistrix className="search_icon" />
              </div>
            </div>
            <div className="search">
              {activeSearch ? (
                <div className="search_holder">
                  <FaSistrix className="search_icon" />
                  <input
                    type="text"
                    placeholder={lang.search.placeholder}
                    title="Please enter minimum 3 characters"
                    onChange={onChange}
                    autoFocus
                  />
                  <FaTimes onClick={closeSearch} className="fa-times" />
                </div>
              ) : (
                <FaSistrix
                  className="search_icon"
                  onClick={() => setActiveSearch(true)}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  lang: state.selectedLanguage,
});

export default connect(mapStateToProps, { setSearchValue })(withRouter(Navbar));
