import { Services } from '../services/Services';

export const radioFavorite = (type, setLoader, fetchAPI, pause, radio_id, activeIndex, stoped) => {
  setLoader(true);
  Services[type]({ radio_id }).then(() => {
    fetchAPI(true);
    if(stoped) {
      if (activeIndex === radio_id) pause();
    }
  });
};
