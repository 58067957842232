import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { FaAngleRight } from 'react-icons/fa';
import Grid from '../../../components/Grid/Grid';
import './NowOnTVSlider.css';

const NowOnTVSlider = ({ lang, locked, channels }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    let arr = [];
    channels.filter(el => el.current_epg).forEach(item => {
      if(!locked.find(el => el === item.category_id)) 
        arr.push({ channel: item, epg: item.current_epg })
    });
    setData(arr);
  }, [channels, locked]);

  return (
    <div className='nowontvslider'>
      <div className='row'>
        <div className='title'>{lang.home.now_on_tv_slider.title}</div>
        <Link to='/channels/nowontv'>
          {lang.home.now_on_tv_slider.btn}
          <FaAngleRight />
        </Link>
      </div>
      <div className='nowontvslider_holder'>
        <div className='grid_items_holder'>
          {data.slice(0, 6).map((item, index) => <Grid key={index} item={item} play_btn={true} /> )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  lang: state.selectedLanguage,
  locked: state.lockedCategories,
  channels: state.channels
})

export default connect(mapStateToProps)(NowOnTVSlider);
