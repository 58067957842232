import React from 'react';
import { connect } from 'react-redux';
import SettingsMenu from '../SettingsMenu/SettingsMenu';

const Help = ({ lang }) => 
  <div className='min_height flex white'>
    <SettingsMenu />
    <div className='settings_content help'>
      <h3>{lang.settings.contact.support_title}</h3>
      <a href='mailto:office@arenaplus.tv'>office@arenaplus.tv</a>
      <h3>{lang.settings.contact.webiste_title}</h3>
      <a target='_blanko' href='https://arenaplus.tv'>https://arenaplus.tv</a>
    </div>
  </div>

const mapStateToProps = state => ({
  lang: state.selectedLanguage
})

export default connect(mapStateToProps)(Help);
