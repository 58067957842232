import React, { useState } from 'react';
import { connect } from 'react-redux';
import { FaQuestion } from 'react-icons/fa';
import { setM3u8Lists } from '../../../redux/actions';
import { Services } from '../../../services/Services';
import RemovedPopup from './RemovedPopup';

const ConfirmPopup = ({ close, list_id, lang }) => {
  const [isRemoved, setRemoved] = useState(false);

  const handleDelete = () => Services.m3u8remove({ list_id }).then(() => setRemoved(true));

  return (
    <>
      <div className='mini_popup' onClick={close}>
        <div className='mini_popup_holder' onClick={e => e.stopPropagation()}>
          <FaQuestion />
          <p>{lang.internet_channels.m3u8_lists.remove_question}</p>
          <button onClick={handleDelete}>OK</button>
        </div>
      </div>
      {isRemoved ? <RemovedPopup /> : null}
    </>
  );
};

const mapStateToProps = state => ({
  lang: state.selectedLanguage
});

export default connect(mapStateToProps, { setM3u8Lists })(ConfirmPopup);
