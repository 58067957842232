import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { FaStar } from 'react-icons/fa';
import { MdPlayCircleOutline, MdPauseCircleOutline } from 'react-icons/md';
import Expandimg from '../../images/expand.png';
import FavoIcon from '../../images/favorites.png';
import { Services } from '../../services/Services';
import { radioFavorite } from '../../helpers/Favorite';
import Track from './Track';
import RadioPlayer from '../../components/RadioPlayer/RadioPlayer';
import { setActiveRadio, setFavoritesRadios, setRadioSliderData, setLoader } from '../../redux/actions';

const RadiosFavorites = ({ lang, radios, setActiveRadio, setFavoritesRadios, setRadioSliderData, setLoader }) => {
  const [fullscreen, setFullscreen] = useState(true);
  const [activeIndex, setActiveIndex] = useState('');
  const [activeItem, setActiveItem] = useState('');
  const [isHover, setIsHover] = useState(false);
  let body = document.querySelector('body');
  
  const fetchAPI = useCallback((loader) => {
    Services.getFavorites({ favorite: '' }).then(res => {
      setFavoritesRadios(res.data.radios);
      if(loader) setLoader(false);
    });
  }, [setFavoritesRadios, setLoader]);

  useEffect(() => {
    fetchAPI();

    return () => {
      pause();
      setActiveRadio([]);
      document.querySelector('body').removeAttribute('style');
    };
  }, [fetchAPI, setActiveRadio]);

  const play = item => {
    setActiveItem(item);
    setActiveIndex(item.id);
  };

  const pause = () => {
    setActiveItem('');
    setActiveIndex('');
  };

  const setIndex = id => setActiveIndex(id);

  const setActive = item => setActiveItem(item);

  const openFullScreen = item => {
    pause();
    setActiveIndex(item.id);
    setActiveItem(item);
    setFullscreen(false);
    body.style.overflow = 'hidden';
    setRadioSliderData(radios);
  };

  const closeFullScreen = () => {
    setFullscreen(true);
    body.removeAttribute('style');
  };

  const toggleFavorite = radio_id => radioFavorite('removeFavorite', setLoader, fetchAPI, pause, radio_id, activeIndex, true);

  return (
    <div className='radio_component'>
      <Track />
      <div className='radio_wrapper'>
        <div className='container'>
          <div className='row'>
            <h2 className='title'>
              {lang.favorites.radios.title}
              <span>{radios.length}</span>
            </h2>
          </div>
          <div className='radio_stations'>
            {radios.length ? (
              radios.map(item => (
                <div key={item.id} className={activeIndex === item.id ? 'radio_stations_item active_border': 'radio_stations_item'}>
                  <img src={item.stream_icon} alt='' className='radio_poster' />
                  {activeIndex !== item.id ? (
                    <div className='radio_station_item_abs' onClick={() => play(item)}>
                      <MdPlayCircleOutline className='radio_stations_item_play' />
                    </div>
                  ) : (
                    <div className='radio_station_item_abs' onClick={pause}>
                      {isHover ? (
                        <MdPauseCircleOutline
                          className='radio_stations_item_stop'
                          onMouseLeave={() => setIsHover(false)}
                        />
                      ) : (
                        <div
                          className='equalizer'
                          onMouseEnter={() => setIsHover(true)}
                        >
                          <div /><div /><div /><div /><div />
                        </div>
                      )}
                    </div>
                  )}

                  <img
                    src={Expandimg}
                    alt='expandimg'
                    className='expandimg'
                    onClick={() => openFullScreen(item)}
                  />
                  {radios.find(el => el.id === item.id) ? (
                    <FaStar
                      className='radio_stations_item_favicon'
                      onClick={() => toggleFavorite(item.id)}
                      style={{ color: '#e2ac37' }}
                    />
                  ) : null}
                </div>
              ))
            ) : (
              <div className='nofavorites'>
                <img src={FavoIcon} alt='favicon' />
                <p>No Favorites</p>
              </div>
            )}
          </div>
        </div>
      </div>
      <RadioPlayer
        isActive={fullscreen}
        data={activeItem}
        closeFullScreen={closeFullScreen}
        setIndex={setIndex}
        setActive={setActive}
        favArr={radios}
      />
    </div>
  );
};

const mapStateToProps = state => ({
  lang: state.selectedLanguage,
  radios: state.favoritesRadios
});

export default connect(mapStateToProps, { setActiveRadio, setFavoritesRadios, setRadioSliderData, setLoader })(RadiosFavorites);
