import React from 'react';
import { connect } from 'react-redux';
import { Services } from '../../services/Services';
import { utility } from '../../helpers/Utility';
import { play_or_prev, isCatchup } from '../../helpers/icons';
import { setInfoModal, setFavoritesChannels, setPlayerVisible } from '../../redux/actions';
import PlayIcon from '../../images/play.png';
import Catchup from '../../images/player_icons/restart1.png';
import RewindIcon from '../../images/rewind.png';
import './InfoModal.css';

const moment = require('moment-timezone');
const timezone = moment.tz.guess();

const InfoModal = ({ data, channels, setInfoModal, favorite, setFavoritesChannels, setPlayerVisible }) => {
  const { channel, epgs, epg } = data;

  const renderIcons = () => {
    let icons = play_or_prev(epg, channel);
    let catchup = isCatchup(channel);

    if (icons === 1) {
      return (
        <div className='icons'>
          {catchup && <img src={Catchup} onClick={handleClick} className='control_icon' alt='' />}
          <img src={PlayIcon} onClick={handleClick} className='control_icon' alt='' />
        </div>
      )
    } else if (icons === 0) return <img src={RewindIcon} onClick={handleClick} className='control_icon' alt='' />
  };

  const toggleFavorite = type => Services[type]({ channel_id: channel.id }).then(() => Services.getFavorites({ favorite: '' }).then(res => setFavoritesChannels(res.data.channels)));

  const handleClick = () => {
    setPlayerVisible({ channels, channel, epgs, epg });
    setInfoModal(null);
  }

  return (
    <div className='info_popup_wrapper' onClick={() => setInfoModal(null)}>
      <div className='info_popup_holder' onClick={e => e.stopPropagation()}>
      <div className='info_popup_img_holder' style={{ background: `url(${epg.epg_img})`}}>
        {renderIcons()}
        <div className='times_icon' onClick={() => setInfoModal(null)} />
        <h2>{epg.title}</h2>
        
      </div>
        <div className='info_popup_body'>
          <div className='info_popup_body_img_holder'>
          <p>
          {moment.tz(epg.start, utility.timezone).tz(timezone).calendar(null, { sameDay: '[Today]', nextDay: '[Tomorrow]', nextWeek: 'DD/MM/YYYY', lastDay: '[Yesterday]', lastWeek: 'DD/MM/YYYY', sameElse: 'DD/MM/YYYY' })} |
          <span>
          {" "}
            {moment.tz(epg.start, utility.timezone).tz(timezone).format('HH:mm')}
            {" "}
          </span>-
          <span>
            {" "}
            {moment.tz(epg.end, utility.timezone).tz(timezone).format('HH:mm')}
            {" "}
          </span>|
          <span>
            {" "}
            {moment.duration(moment(epg.end, 'YYYY/MM/DD HH:mm').diff(moment(epg.start, 'YYYY/MM/DD HH:mm'))).asMinutes()}
             {" "}MIN
          </span>
        </p>
            <img src={channel.stream_icon} alt='' />
          </div>
          <div className='info_popup_text_btn'>
            <p>{epg.description}</p>
          </div>
          {favorite.find(el => el.id === channel.id) ? 
            <button onClick={() => toggleFavorite('removeFavorite')} className='active_nav'>Favorites</button>
            : 
            <button onClick={() => toggleFavorite('addFavorite')}>Favorites</button>
          }
          </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  favorite: state.favoritesChannels,
  data: state.info_modal,
  channels: state.channels
});

export default connect(mapStateToProps, { setInfoModal, setFavoritesChannels, setPlayerVisible })(InfoModal);
