import React from 'react';

export const makeGrid = components => {
  var groups = [];
  var children = [];
  while (components.length > 0) {
    children.push(components.shift());
    if (children.length === 6) {
      groups.push(
        <div
          className='grid_items_holder'
          key={children[0].key && children[0].key}
        >
          {children}
        </div>
      );
      children = [];
    }
  }
  if (children.length > 0) {
    groups.push(
      <div
        className='grid_items_holder'
        key={children[0].key && children[0].key}
      >
        {children}
      </div>
    );
  }

  return groups;
};

export const makeGrid3InRow = components => {
  var groups = [];
  var children = [];
  while (components.length > 0) {
    children.push(components.shift());
    if (children.length === 3) {
      groups.push(
        <div
          className='grid_items_holder'
          key={children[0].key && children[0].key}
        >
          {children}
        </div>
      );
      children = [];
    }
  }
  if (children.length > 0) {
    groups.push(
      <div
        className='grid_items_holder'
        key={children[0].key && children[0].key}
      >
        {children}
      </div>
    );
  }

  return groups;
};