export const defaultSetup = (locked, channels, setTVchannelActiveChannel, setActiveEpgs, activeItem, setActiveItem) => {
  if (channels.length) {
    let isLocked = [];
    let notLocked = [];
    channels.forEach(channel => {
      if (!locked.find(el => el === channel.category_id)) notLocked.push(channel);
      else isLocked.push(channel);
    });
    if(activeItem) {
      setTVchannelActiveChannel(activeItem);
    } else {
      if (notLocked.length || isLocked.length) {
        if (notLocked.length) {
          setTVchannelActiveChannel(notLocked[0]);
          setActiveItem(notLocked[0]);
        } else {
          setTVchannelActiveChannel([]);
          setActiveEpgs([]);
        }
      } else {
        if (channels[0].epgs.length) {
          setTVchannelActiveChannel(channels[0]);
          setActiveItem(channels[0]);
        } else {
          setTVchannelActiveChannel([]);
          setActiveEpgs([]);
        }
      }
    }
  }
};
