import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { FaLock, FaStar, FaRegStar, FaHistory } from 'react-icons/fa';
import { utility } from '../../../../helpers/Utility';
import { Services } from '../../../../services/Services';
import { defaultSetup } from '../../../../helpers/locked';
import { setFavoritesChannels, setTVchannelActiveChannel, setLoader, setPlayerVisible, setPinCodeModal, setChannels, setEpgs } from '../../../../redux/actions';
import './Menu.css';

const moment = require("moment-timezone");
const timezone = moment.tz.guess();

const Menu = ({ channels, lang, favorites, channelsParams, pincode, locked, activeChannel, setActiveEpgs, setChannels, setEpgs, setLoader, setPinCodeModal, setFavoritesChannels, setTVchannelActiveChannel, setPlayerVisible }) => {
  const [result, setResult] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [isLockedChannel, setIsLockedChannel] = useState(null);
  const [activeItem, setActiveItem] = useState(null);

  useEffect(() => {
    return () => {
      setTVchannelActiveChannel([]);
    }
  }, []);

  const fetchFavorite = useCallback(() => {
    Services.getFavorites({ favorite: '' }).then(res => setFavoritesChannels(res.data.channels));
  }, [setFavoritesChannels]);

  useEffect(() => {
    fetchFavorite();
  }, [fetchFavorite]);

  useEffect(() => {
    if (searchValue.length === 0) setResult(channels);
  }, [searchValue, channels]);

  useEffect(() => {
    setActiveItem(null);
  }, [channelsParams])

  useEffect(() => {
    defaultSetup(locked, channels, setTVchannelActiveChannel, setActiveEpgs, activeItem, setActiveItem);
  }, [channels]);

  const activeClass = item => activeChannel.id === item.id ? 'item active' : 'item';

  const handleSearch = e => {
    setSearchValue(e.target.value);
    setResult(channels.filter(channel => channel.name.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1));
  };

  const fetchChannels = () => {
    let param = { filter: '', category: '', sort: '', favorite: '', epgs: 1 };
    Services.getChannels(param).then(res => {
      setChannels(res.data);
      setLoader(false);
    });
  };

  const toggleFavorite = (e, item, type) => {
    if(item.stream_path) setLoader(true);
    e.stopPropagation();
    let obj = {};
    if(type === 'addFavorite') obj = { channel_id: item.id };
    else obj = { m3u_channel_id: item.id };
    Services[type](obj).then(() => {
      fetchFavorite();
      if(item.stream_path) fetchChannels();
    });
  };

  useEffect(() => {
    if(pincode === 'success') setTVchannelActiveChannel(isLockedChannel)
  }, [pincode, setTVchannelActiveChannel, isLockedChannel]);

  const handleClick = channel => {
    let now = moment.tz(new Date(), utility.timezone).tz(timezone).format();
    let limitTime = moment.tz(localStorage.is_locked_time, utility.timezone).add(1, "hour").tz(timezone).format();

    if (channel.stream_path) {
      setPlayerVisible({ channels, channel, epg: channel.current_epg });
      setTVchannelActiveChannel(channel);
      setActiveItem(channel);
      setEpgs([]);
    }
    else {
      if (localStorage.is_locked_time && limitTime < now) {
        localStorage.removeItem("is_locked_time");
        localStorage.setItem('all_locked', false);
        isLocked(channel);
      } else {
         if(localStorage.all_locked === "true") {
            if(activeChannel === channel) {
              setPlayerVisible({ channels, channel, epg: channel.current_epg });
              Services.getEpgs({ channel_id: channel.id }).then(res => setEpgs(res.data));
            } else {
              setTVchannelActiveChannel(channel);
              setActiveItem(channel);
            }
         }
         else {
          if(activeChannel === channel) {
            setPlayerVisible({ channels, channel, epg: channel.current_epg });
            Services.getEpgs({ channel_id: channel.id }).then(res => setEpgs(res.data));
          } else {
            isLocked(channel);
          }
          }
      }
    }
  };

  const isLocked = channel => {
    if(channel.locked) {
      setPinCodeModal('opened');
      setIsLockedChannel(channel);
    } else {
      setTVchannelActiveChannel(channel);
      setActiveItem(channel);
    }
  }

  return (
    <div className='channels_menu'>
      <div className='channels_menu_holder'>
        <div className='input_holder'>
          <input type='text' placeholder={lang.search.placeholder} onChange={handleSearch} />
        </div>
        <div className='items'>
          {result.map(item => (
            <div key={item.id} onClick={() => handleClick(item)} className={activeClass(item)}>
              <div className='left'>
                {item.stream_icon && <img src={item.stream_icon} alt='img' />}
                <p>{item.name}</p>
              </div>
              <div className='right'>
                {locked.find(el => el === item.category_id) && <FaLock className='lock' />}
                {item.tv_archive_duration > 0 && <FaHistory className='history_svg' />}
                {favorites.find(el => el.id === item.id) ? 
                  <FaStar onClick={e => toggleFavorite(e, item, 'removeFavorite')} className='yellow'/>
                  : 
                  <FaRegStar onClick={e => toggleFavorite(e, item, 'addFavorite')} />
                }
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  lang: state.selectedLanguage,
  channels: state.channels,
  favorites: state.favoritesChannels,
  locked: state.lockedCategories,
  pincode: state.pinCodeModal,
  activeChannel: state.tvChannelsActiveChannel,
  channelsParams: state.channelsParams
});

export default connect(mapStateToProps, { setFavoritesChannels, setPinCodeModal, setChannels, setLoader, setTVchannelActiveChannel, setEpgs, setPlayerVisible })(Menu);
