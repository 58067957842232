import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

const Track = ({ lang }) => {
  const isActive = path => window.location.pathname === path ? 'active_nav' : '';

  return (
    <div className='channels_track jcc favorites_track'>
      <div className='channels_track_buttons'>
        <Link to='/favorites/channel' className={isActive('/favorites/channel')}>
          {lang.favorites.submenu.channels}
        </Link>
        <Link to='/favorites/radios' className={isActive('/favorites/radios')}>
          {lang.favorites.submenu.radios}
        </Link>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  lang: state.selectedLanguage
});

export default connect(mapStateToProps)(Track);
