import { utility } from '../../../helpers/Utility';

const moment = require('moment-timezone');
const timezone = moment.tz.guess();
const now = moment.tz(new Date(), utility.timezone).tz(timezone).format();
const internet_url = 'https://dev.arenaplus.fastcloudnetwork.com/api/proxy?url=';

export const config = channel => ({
  file: {
    hlsOptions: {
      xhrSetup: (xhr, url) => channel.stream_path && xhr.setRequestHeader('custom', channel.stream_path)
    }
  }
});

export const timeInterval = (epg, player) => moment.tz(epg.start, utility.timezone).add(player.current && player.current.getCurrentTime() ? player.current.getCurrentTime() : 0, 'seconds').tz(timezone).format('HH:mm:ss');

export const printTime = epg => moment.tz(epg.end, utility.timezone).tz(timezone).format('HH:mm:ss');

export const streaming = (channel, epgs, epg) => {
  // console.log(channel)
  if (channel.stream_path) return `${internet_url}${channel.stream_path}`;
  else {
    let previousEpgs = epgs && epgs.length ? epgs.filter(item => moment.tz(item.end, utility.timezone).tz(timezone).format() < now) : [];
    if (epg && previousEpgs.includes(epg) && channel.tv_archive_duration > 0) return epg.src + `?token=${localStorage.getItem('stream_token')}`
    else return `${channel.current_epg.src}?token=${localStorage.stream_token}`;
  }
};