import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import './Track.css';

const Track = ({ lang }) => {
  const langauge = lang.internet_channels.submenu;
  const isActive = path => window.location.pathname === path ? 'active_nav' : '';

  return (
    <div className='internetTVTrack_wrapper'>
      <Link to='/internetTV/m3u8' className={isActive('/internetTV/m3u8')}>
        {langauge.channels}
      </Link>
      <Link to='/internetTV/addTVchannels' className={isActive('/internetTV/addTVchannels')}>
        {langauge.add_channels}
      </Link>
      <Link to='/internetTV/addM3U8list' className={isActive('/internetTV/addM3U8list')}>
        {langauge.add_m3u8_list}
      </Link>
      <Link to='/internetTV/m3u8lists' className={isActive('/internetTV/m3u8lists')}>
        {langauge.m3u8_lists}
      </Link>
    </div>
  );
};

const mapStateToProps = state => ({
  lang: state.selectedLanguage
})

export default connect(mapStateToProps)(Track);
