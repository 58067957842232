import { utility } from "../helpers/Utility";

export const Services = {};

const baseUrl = utility.baseUrl;

Services.getChannels = (data) => {
  return utility.axiosInstance().post(`${baseUrl}channels`, data);
};

Services.getCategories = (data) => {
  return utility.axiosInstance().post(`${baseUrl}categories`, data);
};

Services.getRadioCategories = (data) => {
  return utility.axiosInstance().post(`${baseUrl}radio/categories`, data);
};

Services.getEpgs = (data) => {
  return utility.axiosInstance().post(`${baseUrl}epgs`, data);
};

Services.getRadios = (data) => {
  return utility.axiosInstance().post(`${baseUrl}radios`, data);
};

Services.allLanguages = (data) => {
  return utility.axiosInstance().get(`${baseUrl}languages`, data);
};

Services.getLanguage = (data) => {
  return utility.axiosInstance().get(`${baseUrl}language`, data);
};

Services.changeLanguage = (data) => {
  return utility.axiosInstance().post(`${baseUrl}change-language`, data);
};

Services.getFavorites = (data) => {
  return utility.axiosInstance().post(`${baseUrl}favorites`, data);
};

Services.addFavorite = (data) => {
  return utility.axiosInstance().post(`${baseUrl}favorite/add`, data);
};

Services.removeFavorite = (data) => {
  return utility.axiosInstance().post(`${baseUrl}favorite/remove`, data);
};

Services.search = (data) => {
  return utility.axiosInstance().post(`${baseUrl}search`, data);
};

Services.epgsDates = (data) => {
  return utility.axiosInstance().post(`${baseUrl}epg-dates`, data);
};

Services.epgsDailys = (data) => {
  return utility.axiosInstance().post(`${baseUrl}daily-epgs`, data);
};

Services.searchEpgs = (data) => {
  return utility.axiosInstance().post(`${baseUrl}epg/search`, data);
};

Services.m3u8lists = (data) => {
  return utility.axiosInstance().get(`${baseUrl}m3u/lists`, data);
};

Services.m3u8channels = (data) => {
  return utility.axiosInstance().get(`${baseUrl}m3u/channels?limit=${data}`);
};

Services.m3u8upload = (data) => {
  return utility.axiosInstance().post(`${baseUrl}m3u/upload`, data);
};

Services.m3u8remove = (data) => {
  return utility.axiosInstance().post(`${baseUrl}m3u/delete`, data);
};

Services.internetChannels = (data) => {
  return utility.axiosInstance().get(`${baseUrl}internet-channels`, data);
};

Services.searchInternetChannels = (data) => {
  return utility.axiosInstance().post(`${baseUrl}internet-channels/search`, data);
};

Services.internetChannelsAdd = (data) => {
  return utility.axiosInstance().post(`${baseUrl}internet-channels/add`, data);
};

Services.internetChannelsRemove = (data) => {
  return utility.axiosInstance().post(`${baseUrl}internet-channels/remove`, data);
};

Services.getCatchUpChannels = (data) => {
  return utility.axiosInstance().post(baseUrl + 'catch-up/channels', data);
};

Services.getBanners = () => {
  return utility.axiosInstance().get(baseUrl + 'banner');
};

Services.setPincode = (data) => {
  return utility.axiosInstance().post(`${baseUrl}change/pincode`, data);
};

Services.lockCategory = (data) => {
  return utility.axiosInstance().post(`${baseUrl}lock/category`, data);
};

Services.unlockCategory = (data) => {
  return utility.axiosInstance().post(`${baseUrl}unlock/category`, data);
};

Services.getUser = () => {
  return utility.axiosInstance().get(`${baseUrl}user`);
};

Services.getInternetChannelsFilters = () => {
  return utility.axiosInstance().get(`${baseUrl}internet-channels/filters`)
};

Services.onTV = (period, sort, category) => {
  return utility.axiosInstance().get(`${baseUrl}on-tv?period=${period}&sort=${sort}&per_page=200&category=${category}`)
};

Services.news_countries = () => {
  return utility.axiosInstance().get(`${baseUrl}news/countries`);
};

Services.news_filter = country_id => {
  return utility.axiosInstance().get(`${baseUrl}news/filter?country_id=${country_id}`);
};

Services.news_feed = (country_id, filter_id) => {
  return utility.axiosInstance().get(`${baseUrl}news/feed?country_id=${country_id}&filter_id=${filter_id}`);
};