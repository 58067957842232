import React , {useState , useEffect} from 'react';

const Progress = ({ player , played , enableSeeking , disableSeeking }) => {
  const [playedState,setPlayedState] = useState(played)

  useEffect(() => {
    setPlayedState(played)
  },[played])
    
    
 const handleSeekMouseDown = e => {
 enableSeeking()
  }
  
  const handleSeekChange = e => {
    setPlayedState(parseFloat(e.target.value))
  }
  
  const handleSeekMouseUp = e => {
    disableSeeking()
    player.current.seekTo(parseFloat(e.target.value))
  }
  

  return (
    <div className='progress'>
    <input
      type='range' min={0} max={0.999999} step='any'
      value={playedState}
      onMouseDown={handleSeekMouseDown}
      onChange={handleSeekChange}
      onMouseUp={handleSeekMouseUp}
    />
  </div>
  )
}
 

export default Progress;
