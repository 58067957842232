import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { FaAngleDown } from "react-icons/fa";
import { Services } from "../../services/Services";
import { makeGrid3InRow } from "../../helpers/makeGrid";
import { setNewsFeed, setLoader } from "../../redux/actions";
import Grid from "./Grid";
import "./News.css";

const News = ({ lang, data, setNewsFeed, setLoader }) => {
  const [dropdown1, setDropdown1] = useState(false);
  const [dropdown1Value, setDropdown1Value] = useState(null);
  const [dropdown2, setDropdown2] = useState(false);
  const [dropdown2Value, setDropdown2Value] = useState("");

  const [countries, setCountries] = useState([]);
  const [filtered, setFiltered] = useState([]);

  const elements = data || [];
  const components = elements.map((item, index) => (
    <Grid key={index} item={item} />
  ));

  useEffect(() => {
    setLoader(true);
    Services.news_countries().then((res) => {
      const countriesAll = [{ id: 0, name: "All Countries", flag: null }];
      countriesAll.push(...res.data);
      // console.log(countriesAll)

      setDropdown1Value(countriesAll[0]);
      setCountries(countriesAll);
      Services.news_filter(countriesAll[0].id).then((res2) => {
        // DODATI I SVE VESTI
        const newsAll = [{ id: 0, name: "All News" }];
        newsAll.push(...res2.data);
        setFiltered(newsAll);
        setDropdown2Value(newsAll[0]);
        Services.news_feed(countriesAll[0].id, newsAll[0].id).then((res3) => {
          setNewsFeed(res3.data);
          setLoader(false);
        });
      });
    });
  }, []);

  const handleCountry = (item) => {
    setLoader(true);
    setDropdown1Value(item);
    Services.news_filter(item.id).then((res) => {
      const newsAll = [{ id: 0, name: "All News" }];
      newsAll.push(...res.data);
      setFiltered(newsAll);
      setDropdown2Value(newsAll[0]);
      Services.news_feed(item.id, newsAll[0].id).then((res) => {
        setLoader(false);
        setNewsFeed(res.data);
        // console.log(res)
      });
    });
  };

  const handleFilter = (item) => {
    setLoader(true);
    Services.news_filter(dropdown1Value.id).then(() => {
      setDropdown2Value(item);
      Services.news_feed(dropdown1Value.id, item.id).then((res) => {
        setLoader(false);
        setNewsFeed(res.data);
        // console.log(res)
      });
    });
  };

  return (
    <div className="min_height white now_on_tv_screen news_screen">
      <div className="container">
        <div className="row">
          <h2 className="title">{lang.menu.news}</h2>
          <div>
            <div className="dropdown" onClick={() => setDropdown1(!dropdown1)}>
              <p>{dropdown1Value && dropdown1Value.name}</p>
              <FaAngleDown className={dropdown1 && "rotate"} />
              {dropdown1 && (
                <div className="dropdown_options">
                  {countries.length &&
                    countries.map((item) => (
                      <p key={item.id} onClick={() => handleCountry(item)}>
                        {item.name}
                      </p>
                    ))}
                </div>
              )}
            </div>
            <div className="dropdown" onClick={() => setDropdown2(!dropdown2)}>
              <p>{dropdown2Value && dropdown2Value.name}</p>
              <FaAngleDown className={dropdown2 && "rotate"} />
              {dropdown2 && (
                <div className="dropdown_options">
                  {filtered.length &&
                    filtered.map((item) => (
                      <p key={item.id} onClick={() => handleFilter(item)}>
                        {item.name}
                      </p>
                    ))}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="grid_items_wrapper">{makeGrid3InRow(components)}</div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  lang: state.selectedLanguage,
  data: state.newsFeed,
});

export default connect(mapStateToProps, { setNewsFeed, setLoader })(News);
