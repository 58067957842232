import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { MdLaptopMac } from 'react-icons/md';
import { IoIosList, IoMdRadio } from 'react-icons/io';
import { FiGrid } from 'react-icons/fi';
import { setCategories, setRadioCategories } from '../../../redux/actions';
import { Services } from '../../../services/Services';
import Categories from '../../../components/Categories/Categories';
import './Track.css';

const Track = ({ grid, setGrid, lang, categories, setCategories, radioCategories, setRadioCategories, categoryName, categoryRadioName }) => {
  const [status, setStatus] = useState(false);
  const language = lang.channels.submenu;

  useEffect(() => {
    Services.getCategories({ filter: '', category: '',  sort: '', favorite: '' }).then(res => setCategories(res.data));
  }, [setCategories]);

  useEffect(() => {
    Services.getRadioCategories({ category: '', favorite: '' }).then(res => setRadioCategories(res.data.categories));
  }, [setRadioCategories]);

  const isActiveRoute = () => window.location.pathname === '/channels/radios' ? true : false;

  const activeClass = path => window.location.pathname === `/channels/${path}` ? 'active_nav' : '';

  const activeGrid = id => grid === id ? 'channels_track_list_icon active_nav' : 'channels_track_list_icon';

  return (
    <>
      <div className='channels_track'>
        <div />
        <p className='categorie_name'>
          {isActiveRoute() ? categoryRadioName : categoryName}
        </p>
        <div className='channels_track_menu_icon' onClick={() => setStatus(!status)}>
          <span>
            {isActiveRoute() ? <IoMdRadio /> : <MdLaptopMac />}
          </span>
        </div>
        <div className='channels_track_buttons'>
          <Link to='/channels/nowontv' className={activeClass('nowontv')}>
            {language.now_on_tv}
          </Link>
          <Link to='/channels/epgs' className={activeClass('epgs')}>
            {language.channels}
          </Link>
          <Link to='/channels/radios' className={activeClass('radios')}>
            {language.radios}
          </Link>
        </div>
        <div className='channels_track_list_icons'>
          <div className={activeGrid(0)} onClick={() => setGrid(0)}>
            <IoIosList />
          </div>
          <div className={activeGrid(1)} onClick={() => setGrid(1)}>
            <FiGrid />
          </div>
        </div>
      </div>
      {isActiveRoute() ? 
        <Categories activeCategories={radioCategories} status={status} close={() => setStatus(false)} data={{ name: 'All' }} />
        : 
        <Categories activeCategories={categories} status={status} close={() => setStatus(false)} data={{ name: 'All' }} />
      }
    </>
  );
};

const mapStateToProps = state => ({
  lang: state.selectedLanguage,
  categories: state.categories,
  radioCategories: state.radioCategories,
  categoryName: state.categoryName,
  categoryRadioName: state.categoryRadioName
})

export default connect(mapStateToProps, { setCategories, setRadioCategories })(Track);
