import { utility } from './Utility';

const moment = require('moment-timezone');
const timezone = moment.tz.guess();

export const play_or_prev = (epg, channel) => {
  let now = moment.tz(new Date(), utility.timezone).tz(timezone).format();
  let start = moment.tz(epg.start, utility.timezone).tz(timezone).format();
  let end = moment.tz(epg.end, utility.timezone).tz(timezone).format();

  let isPlay = now > start && now <= end;
  let catchup = channel.tv_archive_duration > 0;

  if (isPlay) return 1;
  else if (now > start && catchup) return 0;
};

export const isCatchup = channel => {
  let catchup = channel.tv_archive_duration > 0;
  if (catchup) return 2;
};
