import React, { useState } from 'react';
import Carousel from './Carousel/Carousel';
import RadioSlider from './RadioSlider/RadioSlider';

const Home = () => {
  const [opacity, setOpacity] = useState(1);

  const onWheel = e => {
    if (e.deltaY > 0) {
      if (opacity > 0.7) setOpacity(opacity - 0.1);
    } else {
      if (opacity < 1) setOpacity(opacity + 0.1);
    }
  };

  return (
    <div className='home_screen' onWheel={onWheel}>
      <Carousel opacity={opacity} />
      <RadioSlider />
    </div>
  );
};

export default Home;
