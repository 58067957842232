import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { FaCheck } from 'react-icons/fa';
import { IoMdArrowDropup, IoMdArrowDropdown } from 'react-icons/io';
import { Services } from '../../../services/Services';

const ByGenre = ({ language, genres, visible, toggle }) => {
  const [activeItem, setActiveItem] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [results, setResults] = useState([]);
  const [added, setAdded] = useState([]);

  useEffect(() => {
    if (genres.length) setActiveItem(genres[0]);
  }, [genres]);

  useEffect(() => {
    setActiveItem(genres[currentIndex]);
  }, [genres, currentIndex]);

  const fetchChannels = useCallback(() => {
    Services.searchInternetChannels({ category_id: activeItem.id })
    .then((res) => {
      setResults(res.data.channels);
      setAdded(res.data.selected_channels_ids);
    });
  }, [activeItem]);

  useEffect(() => {
    if (activeItem) fetchChannels();
  }, [activeItem, fetchChannels]);

  const slideUp = e => {
    e.stopPropagation();
    if (currentIndex > 0) setCurrentIndex(currentIndex - 1);
    else setCurrentIndex(genres.length - 1);
  };

  const slideDown = e => {
    e.stopPropagation();
    if (currentIndex < genres.length - 1) setCurrentIndex(currentIndex + 1);
    else setCurrentIndex(0);
  };

  const handleClick = (item, index) => {
    setActiveItem(item);
    setCurrentIndex(index);
  };

  const toggleClick = id => {
    let params = { channels_ids: [id] };
    if (added.includes(id)) Services.internetChannelsRemove(params).then(() => fetchChannels());
    else Services.internetChannelsAdd(params).then(() => fetchChannels());
  };

  return (
    <>
      <label>{language.search_by_genre_label}</label>
      <div className='select scroll'>
        <div onClick={() => toggle(!visible)} className='top'>
          {activeItem && activeItem.category_name}
          <div className='arrows'>
            <IoMdArrowDropup onClick={slideUp} />
            <IoMdArrowDropdown onClick={slideDown} />
          </div>
        </div>
        {visible ? (
          <div className='options'>
            {genres.length
              ? genres.map((item, index) => (
                  <p
                    key={index}
                    onClick={() => handleClick(item, index)}
                    className={item.id === activeItem.id ? 'active' : ''}
                  >
                    {item.category_name}
                  </p>
                ))
              : null}
          </div>
        ) : null}
      </div>
      <div className='byName_items'>
        {results.length ? (
          results.map((item) => (
            <div className='item' key={item.id}>
              <div
                className='img'
                style={{ background: `url(${item.stream_icon})` }}
              />
              <p title={item.name}>
                {item.name.length > 20
                  ? item.name.substring(0, 20) + '...'
                  : item.name}
              </p>
              <div className='checkbox' onClick={() => toggleClick(item.id)}>
                {added.find((el) => el === item.id) ? <FaCheck /> : null}
              </div>
            </div>
          ))
        ) : (
          <p>No Result!</p>
        )}
      </div>
    </>
  );
};

const mapStateToProps = state => state;

export default connect(mapStateToProps)(ByGenre);
