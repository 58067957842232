import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Services } from './services/Services';
import { setSelectedLanguage, setLockedCategories, setPinCode, setChannels, setLoader } from './redux/actions';
import Routes from './Routes';
import Spinner from './Spinner';
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';
import Player from './components/VideoPlayer/Player/Player';
import InfoModal from './components/InfoModal/InfoModal';
import PINcode from './components/PINcode/PINcode';
import LeftSliderPIN from './components/VideoPlayer/LeftSlider/LeftSliderPIN';

const Root = ({ info_modal, pincode, left_slide_pin, loader, player, lang, setSelectedLanguage, setLockedCategories, setPinCode, setChannels, setLoader }) => {
  useEffect(() => {
    let param = { filter: '', category: '', sort: '', favorite: '', epgs: 1 };

    Services.getUser().then(res => {
      setLockedCategories(res.data.locked_categories);
      setPinCode(res.data.pincode);
    });

    Services.changeLanguage({ language_id: localStorage.locale }).then(() => 
      Services.getLanguage().then(res => setSelectedLanguage(res.data.language))
    );

    Services.getChannels(param).then(res => {
      setChannels(res.data);
      setLoader(false);
    });
  }, [setLockedCategories, setPinCode, setSelectedLanguage, setChannels, setLoader]);

  return (
    <>
      {loader && <Spinner />}
      {player && <Player />}
      {info_modal && <InfoModal />}
      {pincode === 'opened' && <PINcode />}
      {left_slide_pin === 'opened' && <LeftSliderPIN />}
      {lang.menu && 
        <BrowserRouter>
          <Navbar />
          <div className='content'>
            <Routes />
          </div>
          <Footer />
        </BrowserRouter>
      }
    </>
  );
};

const mapStateToProps = state => ({
  loader: state.loader,
  lang: state.selectedLanguage,
  player: state.playerVisible,
  info_modal: state.info_modal,
  pincode: state.pinCodeModal,
  left_slide_pin: state.leftSliderPin
});

export default connect(mapStateToProps, { setSelectedLanguage, setLockedCategories, setPinCode, setChannels, setLoader })(Root);
