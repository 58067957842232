import axios from 'axios';
export const utility = {};
var moment = require('moment-timezone');

utility.baseUrl = 'https://arena-fs.arenaplus.fastcloudnetwork.com/api/';
utility.providerName = 'https://arenaxui.fastcloudnetwork.com:25463';
utility.providerUsername = localStorage.getItem('provider_username');
utility.providerPassword = localStorage.getItem('provider_password');
utility.deviceMacAddres = '00-14-22-01-23-55';
utility.timezone = 'UTC';

utility.axiosInstance = function () {
  return axios.create({
    baseURL: utility.baseUrl,
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      Authorization: 'Bearer ' + localStorage.getItem('jwtToken'),
    },
  });
};

utility.progressBarWidth = function (epg) {
  let now = new Date().getTime();

  let timezone = moment.tz.guess();

  let localTimeStart = moment
    .tz(epg.start, utility.timezone)
    .tz(timezone)
    .format();
  let localTimeEnd = moment.tz(epg.end, utility.timezone).tz(timezone).format();

  let start = new Date(localTimeStart).getTime();
  let end = new Date(localTimeEnd).getTime();

  let duration;
  duration = end - start;

  if (now > start && now < end) {
    let progress = now - start;
    let progressPercent = (100 * progress) / duration;

    return `${progressPercent}%`;
  } else {
    if (now >= end) {
      return '100%';
    } else {
      return '0%';
    }
  }
};

utility.playerProgress = function (epg) {
  let now = new Date().getTime();

  let timezone = moment.tz.guess();

  let localTimeStart = moment
    .tz(epg.start, utility.timezone)
    .tz(timezone)
    .format();
  let localTimeEnd = moment.tz(epg.end, utility.timezone).tz(timezone).format();

  let start = new Date(localTimeStart).getTime();
  let end = new Date(localTimeEnd).getTime();

  let duration;
  duration = end - start;

  if (now > start && now < end) {
    let progress = now - start;
    let progressPercent = (100 * progress) / duration;

    return `${progressPercent}%`;
  } else {
    if (now >= end) {
      return '100%';
    } else {
      return '0%';
    }
  }
};

utility.formatDate = function (dateString) {
  if (dateString) {
    var date = dateString.split(' ')[0];
    var time = dateString.split(' ')[1];

    date = date.split('-').map(function (item) {
      return parseInt(item);
    });

    time = time.split(':').map(function (item) {
      return parseInt(item);
    });

    var formattedDate = new Date();

    formattedDate.setDate(date[2]);
    formattedDate.setMonth(date[1] - 1);
    formattedDate.setFullYear(date[0]);

    formattedDate.setHours(time[0]);
    formattedDate.setMinutes(time[1]);
    formattedDate.setSeconds(0);

    return formattedDate;
  }

  return null;
};

utility.startTimeShiftFormat = function (dateString) {
  let startMiliceconds =
    new Date(utility.formatDate(dateString)).getTime() - 3 * 60 * 60000;

  let startDateObj = new Date(startMiliceconds);

  let fullYear = startDateObj.getFullYear();
  let month =
    startDateObj.getMonth() + 1 < 10
      ? '0' + (startDateObj.getMonth() + 1)
      : startDateObj.getMonth() + 1;
  let date =
    startDateObj.getDate() < 10
      ? '0' + startDateObj.getDate()
      : startDateObj.getDate();

  let hour =
    startDateObj.getHours() < 10
      ? '0' + startDateObj.getHours()
      : startDateObj.getHours();
  let minutes =
    startDateObj.getMinutes() < 10
      ? '0' + startDateObj.getMinutes()
      : startDateObj.getMinutes();

  let startTimrShift =
    fullYear + '-' + month + '-' + date + ':' + hour + '-' + minutes;

  return startTimrShift;
};

utility.getSplitTime = function (epgTime) {
  let time = epgTime.split(' ')[1];
  let timeSplited = time.split(':');

  let hour = '';
  let h = parseInt(timeSplited[0]);

  if (h < 3) {
    switch (h) {
      case 0:
        hour = '21';
        break;
      case 1:
        hour = '22';
        break;
      case 2:
        hour = '23';
        break;
      default:
        break;
    }
  } else {
    if (h - 3 < 10) {
      hour = '0' + h - 3;
    } else {
      hour = h - 3;
    }
  }

  return hour + ':' + timeSplited[1];
};
