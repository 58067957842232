import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { AiOutlineFileExcel } from 'react-icons/ai';
import { FaRegStar, FaStar, FaTimes } from 'react-icons/fa';
import { Services } from '../../../services/Services';
import { setInternetChannels, setFavoritesChannels, setLoader, setPlayerVisible, setM3u8ActiveChannel, setEpgs, setChannels } from '../../../redux/actions';
import Track from '../Track/Track';
import './InternetTvChannels.css';

const InternetTvChannels = ({ lang, channels, activeChannel, favorite, setInternetChannels, setEpgs, setChannels, setM3u8ActiveChannel, setFavoritesChannels, setLoader, setPlayerVisible }) => {
  const [filtered, setFiltered] = useState([]);
  const [limit, setLimit] = useState(100);
  const [realLength, setRealLength] = useState(0);

  const fetchAPI = useCallback(() => {
    setLoader(true);
    Services.m3u8channels().then(res => setRealLength(res.data.channels));
    Services.m3u8channels(limit).then(res => {
      setFiltered(res.data.channels);
      setInternetChannels(res.data.channels);
      setLoader(false);
    });
    Services.getFavorites({ favorite: '' }).then((res) => {
      setFavoritesChannels(res.data.channels);
    });
  }, [setInternetChannels, setFavoritesChannels, setLoader, limit]);

  const fetchChannels = () => {
    let param = { filter: '', category: '', sort: '', favorite: '', epgs: 1 };
    Services.getChannels(param).then(res => setChannels(res.data));
  };

  useEffect(() => {
    fetchAPI()
  }, [fetchAPI]);

  useEffect(() => {
    if(channels.length > 0) setFiltered(channels);
  }, [channels])

  const handleSearch = e => {
    let result = channels.filter(channel => channel.name.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1);
    setFiltered(result);
  };
  
  const addToFavorite = (e, item) => {
    e.stopPropagation();
    let obj = {};
    if (item.stream_path) {
      obj = { m3u_channel_id: item.id };
    } else {
      obj = { channel_id: item.stream_id };
    }
    Services.addFavorite(obj).then(() => {
      fetchAPI();
      fetchChannels();
    });
  };

  const removeToFavorite = (e, item) => {
    e.stopPropagation();
    let obj = {};
    if (item.stream_path) {
      obj = { m3u_channel_id: item.id };
    } else {
      obj = { channel_id: item.stream_id };
    }
    Services.removeFavorite(obj).then(() => {
      fetchAPI();
      fetchChannels();
    });
  };

  const handleDelete = (e, id) => {
    e.stopPropagation();
    Services.internetChannelsRemove({ channels_ids: [id]}).then(() => {
      fetchAPI();
      fetchChannels();
    });
  };

  const handleClick = channel => {
    setEpgs([]);
    setPlayerVisible({ channels, channel, epg: null })
    setM3u8ActiveChannel(channel);
  };

  const activeClass = item => activeChannel === item ? 'item active' : 'item';

  const showMore = () => {
    setLimit(limit + 100);
    fetchAPI();
  };

  return (
    <div className='internet_channels light_bg relative'>
      <Track />
      {channels.length > 0 ? (
        <div className='flex internet_channels_holder'>
          <div className='channels_menu'>
            <div className='channels_menu_holder'>
              <div className='input_holder'>
                <input
                  type='text'
                  placeholder={lang.search.placeholder}
                  onChange={handleSearch}
                />
              </div>
              <div className='items'>
                {filtered.map(item => (
                  <div key={item.id} className={activeClass(item)} onClick={() => handleClick(item)}>
                    <div className='left'>
                      {item.stream_icon ? <img src={item.stream_icon} alt='img' /> : null}
                      <p>{item.name.length > 20 ? item.name.substring(0, 20) + '...' : item.name}</p>
                    </div>
                    <div className='right'>
                      {item.internet_channel === 1 && <FaTimes onClick={e => handleDelete(e, item.id)} className='red' />}
                      {favorite.find(el => el.id === item.id) ? (
                        <FaStar className='yellow' onClick={e => removeToFavorite(e, item)} />
                      ) : (
                        <FaRegStar onClick={e => addToFavorite(e, item)} />
                      )}
                    </div>
                  </div>
                ))}
                {!filtered.length ? null : realLength.length > filtered.length && <button className='show_more' onClick={showMore}>Show more</button>}
              </div>
            </div>
          </div>
          <div className='isEmpty'>
            <AiOutlineFileExcel />
            <p>You have no EPGs.</p>
          </div>
        </div>
      ) : (
        <div className='no_added_internet_channels'>
          <AiOutlineFileExcel />
          <p>You have no channels added.</p>
          <span>add a channel</span>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  lang: state.selectedLanguage,
  channels: state.internetChannels,
  favorite: state.favoritesChannels,
  active_channel: state.m3u8ActiveChannel,
  activeChannel: state.m3u8ActiveChannel
});

export default connect(mapStateToProps, { setLoader, setInternetChannels, setChannels, setEpgs, setM3u8ActiveChannel, setFavoritesChannels, setPlayerVisible })(InternetTvChannels);
