import React from 'react';
import { connect } from 'react-redux';
import { makeGrid } from '../../helpers/makeGrid';
import NoResultImg from '../../images/noresult.png';
import Grid from '../../components/Grid/Grid';
import './Search.css';

const Search = ({ data, lang }) => {
  var elements = data || [];
  var components = elements.map((item, index) => <Grid key={index} item={item} />);

  if (data.length === 0) {
    return (
      <div className='noresult'>
        <img src={NoResultImg} alt='' />
        <p>{lang.search.no_result}</p>
      </div>
    );
  }

  return (
    <div className='min_height white search_page'>
      <div className='container'>
        <h2 className='title'>{lang.search.placeholder}</h2>
        <div className='grid_items_wrapper'>{makeGrid(components)}</div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  data: state.searchValue, 
  lang: state.selectedLanguage
})

export default connect(mapStateToProps)(Search);
