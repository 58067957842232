import React from 'react';
import { connect } from 'react-redux';
import { FaAngleRight } from 'react-icons/fa';
import { Services } from '../../services/Services';
import { utility } from '../../helpers/Utility';
import { play_or_prev } from '../../helpers/icons';
import { setInfoModal, setPlayerVisible, setEpgs } from '../../redux/actions';
import Play from '../../images/play.png';
import Rewind from '../../images/rewind.png';
import NoImg from "../../images/mini_logo.png";
import './Grid.css';

const moment = require('moment-timezone');
const timezone = moment.tz.guess();

const Grid = ({ item, play_btn, channels, setInfoModal, setPlayerVisible, setEpgs }) => {
  const { channel, epg } = item;

  const renderIcons = () => {
    let icons = play_or_prev(epg, channel);
    
    if (icons === 1) return <img src={Play} onClick={handleClick} className='play_icon' alt='' />
    else if (icons === 0) return <img src={Rewind} onClick={handleClick} className='play_icon' alt='' />
  };

  const bg = () => epg.epg_img ? { background: `url(${epg.epg_img})` } : { background: `url(${NoImg})` };

  const handleClick = e => {
    e.stopPropagation();
    setPlayerVisible({ channels, channel, epg });
    Services.getEpgs({ channel_id: channel.id }).then(res => setEpgs(res.data));
  };

  const openModal = () => {
    setInfoModal({ channel, epg });
    Services.getEpgs({ channel_id: channel.id }).then(res => setEpgs(res.data));
  };

  return (
    <div className='grid_item'>
      <div className='grid_item_top' onClick={openModal} style={bg()}>
        {play_btn ? <img src={Play} onClick={handleClick} alt='' className='play_icon' /> : renderIcons()}
      </div>
      <div className='grid_item_bottom'>
        <div className='proggres_bar'>
          <div style={{ width: utility.progressBarWidth(epg) }} className='buffer' />
        </div>
        <h2 className='epg_title'>
          {epg.title.length > 10 ? epg.title.substring(0, 10) + '...' : epg.title}
        </h2>
        <h3 className='epg_date'>
          {moment.tz(epg.start, utility.timezone).tz(timezone).format('HH:mm')}-
          {moment.tz(epg.end, utility.timezone).tz(timezone).format('HH:mm')}
        </h3>
        {channel.stream_icon && <img src={channel.stream_icon} alt='' className='stream_icon'/>}
        <div className='epg_desc'>
          <p>{epg.description && epg.description.length > 50 ? epg.description.substring(0, 50) + '...' : epg.description}</p>
          <span>
            <FaAngleRight onClick={openModal} />
          </span>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  channels: state.channels
});

export default connect(mapStateToProps, { setInfoModal, setPlayerVisible, setEpgs })(Grid);
