import React, { Component } from "react";
import { connect } from "react-redux";
import { IoIosPlay, IoIosRewind } from "react-icons/io";
import { AiOutlineFileExcel } from "react-icons/ai";
import { utility } from "../../../../helpers/Utility";
import { play_or_prev } from "../../../../helpers/icons";
import { setInfoModal, setPlayerVisible } from "../../../../redux/actions";
import MarkerImg from "../../../../images/marker.png";
import "./List.css";

const moment = require("moment-timezone");
const timezone = moment.tz.guess();
const now = moment.tz(new Date(), utility.timezone).tz(timezone).format();
var offset = 0;

class List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      epgsData: []
    };
  }

  componentDidMount() {
    this.sortByDates(this.props.epgs);
    this.markerSetup();
  }

  UNSAFE_componentWillReceiveProps(prevProps, nextProps) {
    this.sortByDates(prevProps.epgs);
  }

  componentDidUpdate() {
    this.markerSetup();
  }

  sortByDates = epgs => {
    if (epgs) {
      var groupBy = function (xs, key) {
        return xs.reduce(function (rv, x) {
          (rv[moment(x.start).format("L")] =
            rv[moment(x.start).format("L")] || []).push(x);
          return rv;
        }, {});
      };
      this.setState({ epgsData: groupBy(epgs, "start") });
    }
  };

  markerSetup = () => {
    const { channel } = this.props;
    var div = document.querySelector(".epglists");
    let top = document.querySelector(".marker-top-epgs");
    let bottom = document.querySelector(".marker-bottom-epgs");

    if (channel.current_epg && channel.current_epg !== null) {
      let currentEpg = channel.current_epg.id;
      let currentIndex = [];
      let todayDate = "";
      Object.entries(this.state.epgsData).forEach(([key, value]) => {
        if (moment(now).format("L") === key) {
          todayDate = key;
          currentIndex = value.map((el) => el.id).indexOf(currentEpg);
        }
      });
      let currentDiv = document.getElementById(`epg_${todayDate}/${currentIndex}`);
      if (currentDiv !== null) {
        let currentDivOffset = currentDiv.offsetTop;
        div.scrollTop = currentDivOffset;
        offset = currentDivOffset;
        div.addEventListener("scroll", this.marker);
      }
    } else {
      top.style.display = "none";
      bottom.style.display = "none";
      div.scrollTop = 0;
      div.removeEventListener("scroll", this.marker);
    }
  };

  marker = () => {
    var div = document.querySelector(".epglists");
    let element = document.querySelector(".epglists_item");
    let top = document.querySelector(".marker-top-epgs");
    let bottom = document.querySelector(".marker-bottom-epgs");
    let elHeight = element.clientHeight;

    if (offset > div.scrollTop + window.innerHeight - elHeight - 100) {
      top.style.display = "none";
      bottom.style.display = "block";
    } else if (offset < div.scrollTop - elHeight) {
      top.style.display = "block";
      bottom.style.display = "none";
    } else {
      top.style.display = "none";
      bottom.style.display = "none";
    }
  };

  goToMarker = (e) => {
    e.stopPropagation();
    const { channel } = this.props;
    var div = document.querySelector(".epglists");
    if (channel.current_epg && channel.current_epg !== null) {
      let currentEpg = channel.current_epg.id;
      let currentIndex = [];
      let todayDate = "";
      Object.entries(this.state.epgsData).forEach(([key, value]) => {
        if (moment(now).format("L") === key) {
          todayDate = key;
          currentIndex = value.map((el) => el.id).indexOf(currentEpg);
        }
      });
      let currentDiv = document.getElementById(`epg_${todayDate}/${currentIndex}`);
      if (currentDiv !== null) {
        let currentDivOffset = currentDiv.offsetTop;
        div.scrollTop = currentDivOffset;
        offset = currentDivOffset;
        div.addEventListener("scroll", this.marker);
      }
    }
  };

  renderIcons = epg => {
    const { channel } = this.props;
    let icons = play_or_prev(epg, channel);

    if (icons === 1) {
      return (
        <>
          <img src={MarkerImg} onClick={this.goToMarker} className='marker-center-epgs' alt='' />
          <span onClick={e => this.handleClick(e, epg)}>
            <IoIosPlay />
          </span>
        </>
      )
    } else if (icons === 0) {
      return (
        <span onClick={e => this.handleClick(e, epg)}>
          <IoIosRewind className="rewind" />
        </span>
      )
    }
  };

  handleClick = (e, epg) => {
    const { channels, channel, setPlayerVisible } = this.props;
    e.stopPropagation();
    setPlayerVisible({ channels, channel, epg });
  };

  openInfoModal = epg => this.props.setInfoModal({ channel: this.props.channel, epg });

  render() {
    const { epgsData } = this.state;
    return (
      <>
        <img onClick={this.goToMarker} src={MarkerImg} className="marker-top-epgs" alt="" />
        <img onClick={this.goToMarker} src={MarkerImg} className="marker-bottom-epgs" alt="" />
        <div className="epglists">
          <div className="fixed_shadow" />
          {Object.entries(epgsData).length > 0 ? 
            Object.entries(epgsData).map(([key, value]) => (
              <div key={key} className="epglists_item_holder">
                <div className="epglists_item_date">
                  {moment.tz(key, utility.timezone).tz(timezone).calendar(null, {sameDay: "[Today], LL", nextDay: "[Tomorrow], LL", nextWeek: "LL", lastDay: "[Yesterday], LL", lastWeek: "LL", sameElse: "LL" })}
                </div>
                {value.map((item, index) => (
                  <div key={item.id} id={`epg_${key}/${index}`} className="epglists_item"
                    onClick={() => this.openInfoModal(item)}>
                    <div className="epglists_item_row">
                      <h5>{moment.tz(item.start, utility.timezone).tz(timezone).format("HH:mm")}</h5>
                      <h2>{item.title}</h2>
                    </div>
                    <span>{this.renderIcons(item)}</span>
                    <div className="proggres_bar">
                      <div className="buffer" style={{ width: utility.progressBarWidth(item) }} />
                    </div>
                  </div>
                ))}
              </div>
            ))
           : 
            <div className="nochannel_wrap">
              <AiOutlineFileExcel />
              You have no EPGs.
            </div>
          }
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  channels: state.channels,
  channel: state.tvChannelsActiveChannel
})

export default connect(mapStateToProps, { setInfoModal, setPlayerVisible })(List);
