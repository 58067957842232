import React, { useState } from 'react';
import { FaCheck } from 'react-icons/fa';
import { Services } from '../../../services/Services';

const BySearch = ({ language }) => {
  const [searchedText, setSearchedText] = useState('');
  const [results, setResults] = useState([]);
  const [added, setAdded] = useState([]);

  const fetchChannels = value => {
    Services.searchInternetChannels({ term: value, limit: 100 })
    .then(res => {
      setResults(res.data.channels);
      setAdded(res.data.selected_channels_ids);
    });
  };

  const handleChange = e => {
    setSearchedText(e.target.value);
    fetchChannels(e.target.value);
  };

  const toggleClick = id => {
    let params = { channels_ids: [id] };
    if (added.includes(id)) Services.internetChannelsRemove(params).then(() => fetchChannels(searchedText));
    else Services.internetChannelsAdd(params).then(() => fetchChannels(searchedText));
  };

  return (
    <>
      <label>{language.search_by_name_label}</label>
      <input type='text' onChange={handleChange} />
      <div className='byName_items'>
        {results.length ? (
          results.map((item) => (
            <div className='item' key={item.id}>
              <div
                className='img'
                style={{ background: `url(${item.stream_icon})` }}
              />
              <p title={item.name}>
                {item.name.length > 20
                  ? item.name.substring(0, 20) + '...'
                  : item.name}
              </p>
              <div className='checkbox' onClick={() => toggleClick(item.id)}>
                {added.find((el) => el === item.id) ? (
                  <FaCheck />
                ) : null}
              </div>
            </div>
          ))
        ) : (
          <p>No Result!</p>
        )}
      </div>
    </>
  );
};

export default BySearch;
