import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Services } from '../../../services/Services';
import { setM3u8Lists } from '../../../redux/actions';
import Track from '../Track/Track';
import SuccessPopup from './SuccessPopup';
import ErrorPopup from './ErrorPopup';
import './AddM3U8list.css';

const AddM3U8list = ({ lang, setM3u8Lists }) => {
  const [url, setUrl] = useState('');
  const [name, setName] = useState('');
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const language = lang.internet_channels.add_m3u8_list;

  const handleSubmit = () => {
    let params = { path: url, name };

    Services.m3u8upload(params)
      .then(res => {
        if (res.data.error) {
          setError(true);
        } else {
          setSuccess(true);
          setM3u8Lists(res.data.m3u_lists);
        }
      })
      .catch(() => setError(true));
  };

  return (
    <div className='addM3U8list_wrapper min_height grey'>
      <Track />
      {success ? <SuccessPopup closePopup={() => setSuccess(false)} /> : null}
      {error ? <ErrorPopup closePopup={() => setError(false)} /> : null}
      <div className='container'>
        <h2 className='title'>{language.label}</h2>
        <div className='row'>
          <div className='input_field'>
            <input
              type='text'
              onChange={(e) => setUrl(e.target.value)}
              placeholder={language.url_placeholder}
            />
            <input
              type='text'
              onChange={(e) => setName(e.target.value)}
              placeholder={language.name_placeholder}
            />
            <button onClick={handleSubmit}>{language.save_btn}</button>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  lang: state.selectedLanguage
});

export default connect(mapStateToProps, { setM3u8Lists })(AddM3U8list);
