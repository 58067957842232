import React from 'react';
import { connect } from 'react-redux';
import SettingsMenu from '../SettingsMenu/SettingsMenu';

const Account = ({ lang }) => {
  const signout = () => {
    localStorage.removeItem('jwtToken');
    localStorage.removeItem('username');
    localStorage.removeItem('package');
    localStorage.removeItem('cookie');
    localStorage.removeItem('expire_date');
    localStorage.removeItem('password');
    window.location.href = '/';
  };

  return (
    <div className='min_height flex white'>
      <SettingsMenu />
      <div className='settings_content account'>
        <h3>{lang.settings.submenu.account}</h3>
        <h5>Username: {localStorage.username}</h5>
        <h5 className='email_address'>Email address: {localStorage.email}</h5>
        <p>{lang.settings.account.current_acount_text}</p>
        <button className='settings_btn' onClick={signout}>
          {lang.settings.account.sign_out_btn}
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  lang: state.selectedLanguage
})

export default connect(mapStateToProps)(Account);
