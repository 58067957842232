import React from 'react';
import { connect } from 'react-redux';
import { FaCheck } from 'react-icons/fa';

const ConfirmPopup = ({ lang }) => {
  const closePopup = () => window.location.href = '/internetTV/m3u8lists';

  return (
    <div className='mini_popup'>
      <div className='mini_popup_holder'>
        <FaCheck />
        <p>{lang.internet_channels.m3u8_lists.remove_success_message}</p>
        <button onClick={closePopup}>OK</button>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  lang: state.selectedLanguage
});

export default connect(mapStateToProps)(ConfirmPopup);
