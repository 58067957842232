import React from 'react';
import { connect } from 'react-redux';
import { web } from './web';
import SettingsMenu from '../SettingsMenu/SettingsMenu';

const Summary = ({ lang }) => 
  <div className='min_height flex white'>
    <SettingsMenu />
    <div className='settings_content summary'>
      <h3>{lang.settings.submenu.summary}</h3>
      <div className='row_between'>
        <p>{lang.settings.summary.package_label}</p>
        <p>{localStorage.package}</p>
      </div>
      <div className='row_between'>
        <p>{lang.settings.account.expire}</p>
        <p>{JSON.stringify(localStorage.expire_date) === null ? null : localStorage.expire_date}</p>
      </div>
      <div className='row_between'>
        <p>{lang.settings.summary.profile_label}</p>
        <p>{localStorage.username}</p>
      </div>
      <div className='row_between'>
        <p>{lang.settings.summary.browser_label}</p>
        <p>{web()}</p>
      </div>
      <div className='row_between'>
        <p>{lang.settings.summary.language_label}</p>
        <p>{lang.name}</p>
      </div>
    </div>
  </div>

const mapStateToProps = state => ({
  lang: state.selectedLanguage
});

export default connect(mapStateToProps)(Summary);
