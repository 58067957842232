import React, { useState, useEffect } from "react";
import { deviceDetect } from "react-device-detect";
import { connect } from "react-redux";
import { FaAngleDown, FaEye } from "react-icons/fa";
import axios from "axios";
import { setLockedCategories, setPinCode } from "../redux/actions";
import Logo from "../images/login_logo.png";
import { utility } from "../helpers/Utility";
import { Services } from "../services/Services";
import "./Login.css";

const Login = ({ setLockedCategories, setPinCode }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [selectStatus, setSelectStatus] = useState(false);
  const [preview, setPreview] = useState(false);
  const [languages, setLanguages] = useState([]);
  const [active_lang, setActive_lang] = useState({});

  useEffect(() => {
    Services.allLanguages().then((res) => {
      setLanguages(res.data.languages);
      setActive_lang(res.data.languages[0]);
    });
  }, []);

  const handleChange = (id) => {
    let current = languages.filter((item) => item.id === id);
    setActive_lang(current[0]);
    setSelectStatus(false);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    // console.log(deviceDetect().userAgent)
    axios
      .post(`${utility.baseUrl}login`, {
        username,
        password,
        mac: deviceDetect().userAgent,
      })
      .then((res) => {
        setError(null);
        let customer = res.data.customer;
        localStorage.setItem("jwtToken", res.data.access_token);
        localStorage.setItem("stream_token", res.data.stream_token);
        localStorage.setItem("username", customer.username);
        localStorage.setItem("email", customer.email);
        localStorage.setItem("expire_date", customer.expire_date);
        localStorage.setItem('locale', active_lang.id);
        localStorage.setItem('pincode', customer.pincode);
        localStorage.setItem('all_locked', false);
        setPinCode(customer.pincode);
        setLockedCategories(customer.locked_categories);
        window.location.href = "/";
      })
      .catch((err) => setError(err.response.data.error));
  };

  const closeSelect = () => {
    if(selectStatus) setSelectStatus(false);
  }

  return (
    <>
      {active_lang.login ? (
        <div className="login_wrapper" onClick={closeSelect}>
          <div className="login_content">
            <img src={Logo} alt="logo" />

            <form onSubmit={onSubmit}>
              <label>
                {active_lang.settings && active_lang.settings.submenu.language}
              </label>
              <div className="custom_select">
                <div
                  className={selectStatus ? "capital rotate" : "capital"}
                  onClick={() => setSelectStatus(!selectStatus)}
                >
                  <p>{active_lang.name && active_lang.name}</p> <FaAngleDown />
                </div>
                {selectStatus ? (
                  <div className="options">
                    {languages.map((item) => (
                      <p key={item.id} onClick={() => handleChange(item.id)}>
                        {item.name}
                      </p>
                    ))}
                  </div>
                ) : null}
              </div>
              <label>
                {active_lang.login && active_lang.login.username_placeholder}
              </label>
              <input
                type="text"
                onChange={(e) => setUsername(e.target.value)}
              />

              <label>
                {active_lang.login && active_lang.login.password_placeholder}
              </label>
              <div className="input_holder">
                <input
                  type={preview ? "text" : "password"}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <FaEye onClick={() => setPreview(!preview)} />
              </div>

              {error ? <p className="error-message">{error}</p> : null}

              <div className="dont_acc">
                {active_lang.login && active_lang.login.no_account}
                <a href="https://arenaplus.tv/" target="_blanko">
                  {active_lang.login && active_lang.login.sign_up}
                </a>
              </div>

              <input
                type="submit"
                value={active_lang.login && active_lang.login.btn}
              />
            </form>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default connect(null, { setLockedCategories, setPinCode })(Login);
