import React, { useState } from "react";
import PinInput from "react-pin-input";
import { connect } from "react-redux";
import { Services } from "../../../services/Services";

const RemovedPin = ({ selectedLanguage, closePopup, data, closeParent }) => {
  const [numbers, setNumbers] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (numbers.length === 4) {
      let obj = {
        pincode: numbers,
        category_id: data.id,
      };
      Services.unlockCategory(obj)
        .then((res) => {
          if (res.data.error) {
            setError(res.data.error);
          } else {
            setError("");
            setTimeout(() => {
              closePopup();
              closeParent();
              window.location.href = "/settings/changepincode";
            }, 1000);
          }
        })
        .catch((err) => setError(err.response.data.error));
    } else {
      setError("PIN code is not a valid. Try again!");
    }
  };

  return (
    <div className="enter_pin_wrapper" onClick={closePopup}>
      <form
        className="enter_pin_holder"
        onClick={(e) => e.stopPropagation()}
        onSubmit={handleSubmit}
      >
        <h2>PIN Code</h2>
        <div>
          <PinInput
            length={4}
            secret
            focus={true}
            onChange={(value) => setNumbers(value)}
            type="numeric"
            style={{ padding: "10px" }}
          />
        </div>
        <p>{error}</p>
        <button onClick={handleSubmit}>Enter PIN Code</button>
      </form>
    </div>
  );
};

const mapStateToProps = (state) => {
  return state;
};

export default connect(mapStateToProps)(RemovedPin);
