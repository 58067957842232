import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { FaLock, FaHistory } from 'react-icons/fa';
import { utility } from '../../../helpers/Utility';
import { Services } from '../../../services/Services';
import { setPlayerVisible, setTVchannelActiveChannel, setLeftSliderPin, setMenuSelectedChannel, setM3u8ActiveChannel, setEpgs } from '../../../redux/actions';
import ArrowImg from '../../../images/arrow.png';
import './LeftSliderStyle.css';

const moment = require("moment-timezone");
const timezone = moment.tz.guess();


const LeftSlider = ({ data , epgs, locked, setPlayerVisible, fullscreen, setM3u8ActiveChannel, setTVchannelActiveChannel, pincode, setLeftSliderPin, setEpgs }) => {
  const { channels, channel } = data;
  const [activePosition, setActivePosition] = useState(0);
  const [isLockedChannel, setIsLockedChannel] = useState(null);

  useEffect(() => {
    if (fullscreen) {
      let itemsNumber = Math.floor(window.innerHeight / 140);
      let limit = (channels.length - itemsNumber) * 140;
      if (activePosition < 0) {
        if (activePosition > -limit) setActivePosition(activePosition + 140);
      }
    }
  }, [fullscreen, channels, activePosition]);

  useEffect(() => {
    if(pincode === 'success') {
      setPlayerVisible({ channels, channel: isLockedChannel, epgs, epg: isLockedChannel.current_epg });
      setTVchannelActiveChannel(isLockedChannel)
      Services.getEpgs({ channel_id: isLockedChannel.id }).then(res => setEpgs(res.data));
    };
  }, [pincode, setTVchannelActiveChannel, isLockedChannel, setPlayerVisible, channels, epgs]);

  const handleScroll = e => {
    if (e.deltaY.toString().includes('-')) slideUp();
    else slideDown();
  };

  const slideUp = () => {
    if (activePosition === 40) setActivePosition(0);
    if (activePosition < 0) setActivePosition(activePosition + 140);
  };

  const slideDown = () => {
    let itemsNumber = Math.floor(window.innerHeight / 140);
    let limit = (channels.length - itemsNumber) * 140;
    if (activePosition > -limit + 70) {
      if (activePosition === -limit + 140) setActivePosition(activePosition - 100);
      else setActivePosition(activePosition - 140);
    }
  };

  const classes = id => id === channel.id ? 'item active' : 'item';

  const handleClick = item => {
    let now = moment.tz(new Date(), utility.timezone).tz(timezone).format();
    let limitTime = moment.tz(localStorage.is_locked_time, utility.timezone).add(1, "hour").tz(timezone).format();

    if(item.stream_path) {
      setM3u8ActiveChannel(item);
      openPlayer(item);
      setEpgs([]);
    } else {
      if (localStorage.is_locked_time && limitTime < now) {
        localStorage.removeItem("is_locked_time");
        localStorage.setItem('all_locked', false);
        isLocked(item);
      } else {
         if(localStorage.all_locked === "true") {
          openPlayer(item); 
          Services.getEpgs({ channel_id: item.id }).then(res => setEpgs(res.data));
         }
         else isLocked(item);
      }
    }
  };

  const isLocked = item => {
    if(item.locked) {
      setLeftSliderPin('opened');
      setIsLockedChannel(item);
    } else {
      openPlayer(item);
      Services.getEpgs({ channel_id: item.id }).then(res => setEpgs(res.data));
    }
  }

  const openPlayer = item => {
    if(window.location.pathname === '/channels/epgs') setTVchannelActiveChannel(item);
    setPlayerVisible({ channels, channel: item, epgs, epg: item.current_epg });
  };

  return (
    <div className='left_slider' onWheel={handleScroll} onClick={e => e.stopPropagation()} onDoubleClick={e => e.stopPropagation()}>
      <div className='left_slider_holder' style={{ transform: `translateY(${activePosition}px)` }}>
        {channels.map(item => (
          <div key={item.id} onClick={() => handleClick(item)} className={classes(item.id)}>
            {item.stream_icon && <img src={item.stream_icon} alt='icon' />}
            <div className="right">
              {item.tv_archive_duration > 0 && <FaHistory />}
              {locked.find(el => el === item.category_id) && <FaLock />}
            </div>
          </div>
        ))}
      </div>
      <div className='arrow top' onClick={slideUp}>
        <img src={ArrowImg} alt='ArrowImg' />
      </div>
      <div className='arrow bottom' onClick={slideDown}>
        <img src={ArrowImg} alt='ArrowImg' />
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  locked: state.lockedCategories,
  pincode: state.leftSliderPin,
  epgs: state.epgs
});

export default connect(mapStateToProps, { setPlayerVisible, setMenuSelectedChannel, setM3u8ActiveChannel, setTVchannelActiveChannel, setLeftSliderPin, setEpgs })(LeftSlider);
