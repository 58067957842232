import React from 'react';
import Logo from './images/root_logo.png';

const Spinner = () => {
  return (
    <div className='spinner-wrapper'>
      <img src={Logo} alt="Arena+" />
      <p>Loading</p>
    </div>
  );
};

export default Spinner;
