import React from 'react';
import Play from '../../../images/player_icons/play.png';
import Pause from '../../../images/player_icons/pause.png';
import { FaUndoAlt, FaRedoAlt} from 'react-icons/fa';

const Controls = ({ player, channel, epg, isPlaying, setIsPlaying , forward , rewind }) => {
  const isCatchup = channel.tv_archive_duration > 0 ? true : false;

  const toggle = () => {
    if (player.current) {
      if (isPlaying) setIsPlaying(false);
      else setIsPlaying(true);
    }
  };

  const handleRewind = () => rewind();

  const handleForward = () => forward();

  return (
    <div className='video_player_icons'>
      {isCatchup && epg && <span onClick={handleRewind}><FaUndoAlt /></span>}
      {isPlaying ? <img src={Pause} onClick={toggle} alt='' /> : <img src={Play} onClick={toggle} alt='' />}
      {isCatchup && epg && <span onClick={handleForward}><FaRedoAlt /></span>}
      
    </div>
  );
};

export default Controls;
