import { Services } from '../services/Services';
import { utility } from '../helpers/Utility';

const moment = require('moment-timezone');
const timezone = moment.tz.guess();
const now = moment.tz(new Date(), utility.timezone).tz(timezone).format();

export const previousEpgs = epgs => {
  let prev = epgs.filter(item => moment.tz(item.end, utility.timezone).tz(timezone).format() < now);
  return prev;
};

export const nextEpgs = epgs => {
  let next = epgs.filter(item => moment.tz(item.start, utility.timezone).tz(timezone).format() > now);
  return next;
};

export const fetchData = (selectValue, langauge, activeChannel, setActiveEpgs, setIsVisible) => {
  setIsVisible(false);
  if (selectValue === langauge.previous) Services.getEpgs({ channel_id: activeChannel.id }).then(res => {
    setActiveEpgs(previousEpgs(res.data));
    setIsVisible(true);
  }); 
  else if (selectValue === langauge.next) Services.getEpgs({ channel_id: activeChannel.id }).then(res => {
    setActiveEpgs(nextEpgs(res.data));
    setIsVisible(true);
  });
  else Services.getEpgs({ channel_id: activeChannel.id }).then(res => {
    setActiveEpgs(res.data);
    setIsVisible(true);
  });
};