import React, { useState, useEffect, useCallback } from 'react';
import FullScreenIcon from '../../../images/player_icons/fullscreen.png';
import VolumeIcon from '../../../images/player_icons/volume.png';
import MuteIcon from '../../../images/player_icons/mute.png';
import { timeInterval, printTime } from './helper';
import { AiOutlineBlock } from "react-icons/ai";

var progressInterval;

const Volume = ({ epg, channel, epgs, player, volume, setVolume, toggleFullScreen , enablePip }) => {
  const [time, setTime] = useState('00:00');

  const startProgress = useCallback(() => {
    clearInterval(progressInterval);
    if (epg && !channel.stream_path && epgs.length > 0)
      progressInterval = setInterval(() => {
        setTime(timeInterval(epg, player));
    }, 1000);
  }, [channel, player, epgs, epg]);

  useEffect(() => {
    startProgress();
  }, [startProgress]);

  return (
    <div className='video_player_controls_right'>
      {epg && <p><span>{time}</span> / <span>{printTime(epg)}</span></p>}
      <div className='volume_holder'>
        {volume > 0 ? <img src={VolumeIcon} onClick={() => setVolume(0)} alt='' /> : <img src={MuteIcon} onClick={() => setVolume(1)} alt='' />}
        <div className='range_holder'>
          <div className='range_wrap'>
            <input type='range' min={0} max={1} step={0.1} value={volume} onChange={e => setVolume(e.target.value)} />
          </div>
        </div>
      </div>
      <div className="enable_pip" 
      onClick={enablePip}
      >
      <AiOutlineBlock />
      </div>
      <img src={FullScreenIcon} onClick={toggleFullScreen} className='fullscreen_icon' alt='' />
    </div>
  );
};

export default Volume;
